.per-page{
  font-size: 0.875em;
  color: #4D4D4D;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin-bottom: 0.1875em;
}
.toolbar {
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
}
.sort-select {
  width: 6rem;
}
.show-select {
  width: 3rem;
}
.amount-padd {
  color: #999;
  padding-bottom: 0.3rem;
  padding-right: 1rem;
}
.grid-padd {
  padding-top: 0.2rem;
}
/*
.view-mode {
  background: #f9f9f9;
  margin-bottom: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  height: 2.5rem;
}

/*
.sort-by {
    background: #f9f9f9;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    margin-bottom: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    height: 2.5rem;
}
.limiter {
  background: #f9f9f9;
  border-right: 1px solid #e5e5e5;
  margin-bottom: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  height: 2.5rem;
}
.tool-padd {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 2rem;
}

.pages-wrapper {
  background: #f9f9f9;
  margin-bottom: 0.4rem;
  padding-left: 1rem;
  padding-right: 0.4rem;
  padding-top: 0.4rem;
  height: 2.5rem;
} */
