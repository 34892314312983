@import "settings";
//@import "../bower_components/foundation/scss/foundation";

// Or selectively include foundation components
@import "../bower_components/foundation/scss/foundation/components/accordion";
//@import "../bower_components/foundation/scss/foundation/components/alert-boxes";
@import "../bower_components/foundation/scss/foundation/components/block-grid";
@import "../bower_components/foundation/scss/foundation/components/breadcrumbs";
//@import "../bower_components/foundation/scss/foundation/components/button-groups";
@import "../bower_components/foundation/scss/foundation/components/buttons";
//@import "../bower_components/foundation/scss/foundation/components/clearing";
@import "../bower_components/foundation/scss/foundation/components/dropdown";
@import "../bower_components/foundation/scss/foundation/components/dropdown-buttons";
//@import "../bower_components/foundation/scss/foundation/components/flex-video";
@import "../bower_components/foundation/scss/foundation/components/forms";
//@import "../bower_components/foundation/scss/foundation/components/global";
@import "../bower_components/foundation/scss/foundation/components/grid";
//@import "../bower_components/foundation/scss/foundation/components/icon-bar";
@import "../bower_components/foundation/scss/foundation/components/inline-lists";
//@import "../bower_components/foundation/scss/foundation/components/joyride";
//@import "../bower_components/foundation/scss/foundation/components/keystrokes";
@import "../bower_components/foundation/scss/foundation/components/labels";
//@import "../bower_components/foundation/scss/foundation/components/magellan";
@import "../bower_components/foundation/scss/foundation/components/offcanvas";
@import "../bower_components/foundation/scss/foundation/components/orbit";
@import "../bower_components/foundation/scss/foundation/components/pagination";
//@import "../bower_components/foundation/scss/foundation/components/panels";
//@import "../bower_components/foundation/scss/foundation/components/pricing-tables";
//@import "../bower_components/foundation/scss/foundation/components/progress-bars";
//@import "../bower_components/foundation/scss/foundation/components/range-slider";
@import "../bower_components/foundation/scss/foundation/components/reveal";
//@import "../bower_components/foundation/scss/foundation/components/side-nav";
//@import "../bower_components/foundation/scss/foundation/components/split-buttons";
//@import "../bower_components/foundation/scss/foundation/components/sub-nav";
//@import "../bower_components/foundation/scss/foundation/components/switches";
@import "../bower_components/foundation/scss/foundation/components/tables";
@import "../bower_components/foundation/scss/foundation/components/tabs";
@import "../bower_components/foundation/scss/foundation/components/thumbs";
//@import "../bower_components/foundation/scss/foundation/components/toolbar";
//@import "../bower_components/foundation/scss/foundation/components/tooltips";
@import "../bower_components/foundation/scss/foundation/components/top-bar";
@import "../bower_components/foundation/scss/foundation/components/type";
@import "../bower_components/foundation/scss/foundation/components/visibility";
@import "../bower_components/foundation/scss/foundation/functions";
@import "../bower_components/owl.carousel/dist/assets/owl.carousel.min.css";
@import "../bower_components/owl.carousel/dist/assets/owl.theme.default.min.css";

// Compass Mixins
@import "../bower_components/compass-mixins/lib/compass";

@mixin maintain-ratio($ratio: 1 1) {
  @if length($ratio) < 2 or length($ratio) > 2 {
    @warn "$ratio must be a list with two values.";
  }
 
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));
  
  width: $width;
  height: 0;
  padding-bottom: $height;
}

// Custom SCSS from now on + Offcanvas
@import "custom/offcanvas";
$fa-font-path: "../bower_components/components-font-awesome/fonts";
@import "../bower_components/components-font-awesome/scss/font-awesome"; // Shamelessly borrowed 'cause they're such great fonts

@import "custom/fontawesome";
@import "custom/easy-responsive-tabs";
@import "custom/scroll-to-top";
@import "custom/flexslider";
// Magento Specific component and Page view SCSS
@import "custom/acc-login";
@import "custom/globals";
@import "custom/global-messages";
@import "custom/form-validation";
@import "custom/header";
@import "custom/checkout";
@import "custom/super-header";
@import "custom/toolbar";
@import "custom/footer";
@import "custom/home-page";
@import "custom/breadcrumbs";
@import "custom/cart";
@import "custom/dashboard";
@import "custom/top-bar";
//Product Page
@import "custom/product-page";
// Catalog Page
@import "custom/catalog-page";
// Reviews
@import "custom/reviews";
// Ratings

@font-face{
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans/eot/FiraSans-UltraLight.eot');
    src: local('Fira Sans UltraLight'),
         url('../fonts/FiraSans/eot/FiraSans-UltraLight.eot') format('embedded-opentype'),
         url('../fonts/FiraSans/ttf/FiraSans-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face{
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans/eot/FiraSans-Light.eot');
    src: local('Fira Sans Light'),
         url('../fonts/FiraSans/eot/FiraSans-Light.eot') format('embedded-opentype'),
         url('../fonts/FiraSans/ttf/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face{
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans/eot/FiraSans-Regular.eot');
    src: local('Fira Sans Regular'),
         url('../fonts/FiraSans/eot/FiraSans-Regular.eot') format('embedded-opentype'),
         url('../fonts/FiraSans/ttf/FiraSans-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face{
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans/eot/FiraSans-Medium.eot');
    src: local('Fira Sans Medium'),
         url('../fonts/FiraSans/eot/FiraSans-Medium.eot') format('embedded-opentype'),
         url('../fonts/FiraSans/ttf/FiraSans-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

#fullWidth {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   max-width: initial;
   background: scale-color(#000000,$alpha:0%);
   position:relative;
   z-index:+10;
}
.site-header{
  position:relative;
   z-index:+10;
}
.fullWidth {
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   max-width: initial;
}

.orbit-container{
    position: fixed;
    top:0;
    //z-index:-100;
    min-height: 100%;
    .orbit-slides-container{
        min-height: 100%;
    }
}

.orbit-slides-container li {
width: 100%;
height: 100%;
background-size: cover;
}

.orbit-container {
width: 100%;
height: 100%;
}

.orbit-slides-container {
width: 100%;
height: 100% !important;
}
.orbit-slides-container li {
width: 100%;
height: 100%;
background-size: cover;
}
.orbit-container {
  width: 100%;
  height: 100%;
}
  
.orbit-slides-container {
  width: 100%;
  height: 100vh !important;
}

ul[data-orbit] li {
  width: 100%;
  height: 100%;
  background-size: cover;
}

h1, h2, p {
  color: $black;
  font-family: 'Open Sans', sans-serif;
}
.y-center {
  position: relative;
  top:40%;
}

.fixed{
  padding:0 5%;
  li.name{
    margin:-1% 0 1% 0;
  }
}

.top-bar{
  .name{
    margin:-1% 0 1% 0;
  }
}

.colmargin{
  padding:0;
}

.owl-nav{
  width:290px;
  margin:0 auto;
  button{
    margin:0.5rem;
  }
}

.orbit-timer, .orbit-slide-number, .orbit-bullets-container{
  display:none;
}

.category-brand{
  .page-title{display:none;}
  .main-container{
    p,h3{
      font-family: 'Euphoria Script', cursive;
      }
      h3{
        font-size:3.5rem;
      }
      p{
        font-size:2.2rem;
      }

  }
}