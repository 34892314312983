.account-login .content {
  min-height: 100px;
}
.account-login {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 40.063rem) {
	.login-heading {
		margin-left: 0.5rem;
	}
	.account-login .content {
		min-height: 120px !important;
	}
}