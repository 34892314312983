.site-language ul,
.site-language li,
.site-language p{
  margin-bottom: 0;padding-bottom: 0;
}
.super-header form, .super-header .top-bar{
  margin: 0;
}
.switchers{
  padding-bottom: 0.5em;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 90px;
}
.welcome-msg {
  padding-left: 0;
}
.alert-box {
  border-width: 1px;
  display: block;
  font-weight: normal;
  margin-bottom: 0;
  position: relative;
  padding: 1em;
  font-size: 0.8125rem;
  background: rgb(179, 255, 179);
  border-color: #e5e5e5;
  color: rgb(40, 141, 40);
}
.super-header {
  background-color: #efefef;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e5e5;
  height: 2.5rem;
}
.site-header {
  margin-top: 0;
  //padding-bottom: 1rem;
  @include background-image(linear-gradient($black, scale-color($black, $lightness:-5%)));
  //border-bottom:1px solid #e5e5e5;
}
.lang-border {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  height: 2.3rem;
}
.welcome-msg {
  margin-top: 0.35rem;
  color: #909090;
}
.super-header select{
  margin-bottom: 0;
  padding: 0 0.4rem !important;
}
select, select:hover {
  padding: 0 0.4rem !important;
  height: 1.6rem;
  padding: 0;
  color: #747474;
  background: #fff url("../images/arrow.png") no-repeat scroll 90% center;
}
.super-header form, .super-header .top-bar {
  margin: 0.4rem;
}
.acc-button {
  border: 1px solid #e5e5e5;
  cursor: pointer;
  line-height: normal;
  margin: 0 0 1.25rem;
  text-decoration: none;
  text-align: center;
  padding-top: 0.55rem;
  padding-right: 1.25rem;
  padding-bottom: 0.55rem;
  padding-left: 1.25rem;
  font-size: 0.6875rem;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  display: inline-block;
  background-color: #f9f9f9;
  color: #747474;
  transition: background-color 300ms ease-out;
}

@media only screen and (max-width: 40em) {
  .acc-button {
    right: 5.4rem;

  }
  .super-header {
    height: 2.45rem;
  }
  .site-identity {
    padding-left: 0;
  }
  .site-search {
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .super-header-content {
    
  }
  .site-identity {
    padding-left: 0;
  }
  .site-search {
    padding-left: 0;
  }
  .top-cart-padd {
    padding-right: 0;
  }
}
@-moz-document url-prefix() {
  .super-header {
    height: 2.5rem;
  }
  .switchers {
    padding-top: 0.1rem;
    width: 100px;
  }
  select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
  }
  select, select:hover {
    padding: 0.19rem;
  }
}

