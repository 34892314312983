.catalog-category-view .grid-mode{
    .price-box{
      .regular-price{
        .price{
        font-size:2rem;
        }
      }
    }
    .product-name{
    font-weight: 500;
    a{
      color:$black;
      transition:all 1s;
      padding:0.2rem;
      margin:-0.2rem;
      &:hover{
        background:$black;
        color:$primary-color;
      }
    }
    margin: 0.5em 0;
  }
  .details{
    font-size:2rem;
    a{
        color:$black;
        transition:all 1s;
        padding:0.2rem;
        margin:-0.2rem;
        &:hover{
          background:$black;
          color:$primary-color;
        }
      }
    }
}

.catalog-category-view .grid-mode  .price-box{
  margin-bottom: 1em;
}
.catalog-category-view .grid-mode .price-box .regular-price, .catalog-category-view .grid-mode .price-box .minimal-price-link{
  @include grid-row(12);
  font-size: $h4-font-size;
}

.catalog-category-view  .desc.std{
  margin-bottom: 1em;
}
.electronics-margin {
  margin-bottom: 3rem !important;
}
.colmargin {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.per-page {
  width: 40px;
}
.sort-by-select {
  margin-right: 1.8rem;
}
.grid-mode {
  padding-right: 0.4rem;
}
.page-title {
  padding:0.5rem;
  margin-top: 0.2rem;
}
#sidebar {
  border: 2px solid #efefef;
  padding: 1rem;
  background: #f9f9f9;
  margin-bottom: 2rem;
}
*[role="main"] {
  padding-left: 1rem;
  padding-bottom: 2rem;
 }
.item {
  padding: 0.5rem !important;
  margin-bottom: 0.9rem;
}
.inner-wrapper {
  //border: 1px solid #efefef;
  padding: 0;
}
.inner-wrapper{
  transition:all 0.5s;
  &:hover {
  -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.10);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.10);
  background:$primary-color;
  }
}
.category-image {
  /*padding-left: 0.1rem;*/
  margin:0 0 1em 0;
}
.category-title{
  //padding:0 0 0 1rem;
}
.category-description {
  padding-left: 0;
  padding-bottom: 1rem;
}
.nephritis-flat-button {
  position: relative;
  vertical-align: top;
  width: 85%;
  padding: 0;
  font-size: 1rem !important;
  color: white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background-color: $backgroundstrong !important;
  border: 0;
  border-bottom: 2px solid $backgroundstrong;
  cursor: pointer;
  -webkit-box-shadow: inset 0 -2px scale-color($backgroundstrong, $lightness: -14%);
  box-shadow: inset 0 -2px scale-color($backgroundstrong, $lightness: -14%);
}
.nephritis-width {
  width: 140px;
}
nephritis-flat-button:hover, .nephritis-flat-button:hover {
  background-color: scale-color($backgroundstrong, $lightness: -14%) !important;
}
.actions .nephritis-flat-button {
margin-bottom: 0;
}
.toolbar{
  .view-mode{
  margin:0;
}
}

.view-mode strong.grid {
  display:block;
  width: 20px;
  height: 20px;
  background: url(../images/grid.png) no-repeat top left transparent;
  font-size: 0;
  text-indent: -9999px;
}
.view-mode strong.list {
  display:block;
  width: 20px;
  height: 20px;
  background: url(../images/list.png) no-repeat bottom left transparent;
  font-size: 0;
  text-indent: -9999px;
}
.view-mode a.grid {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../images/grid-light.png) no-repeat top right transparent;
  font-size: 0;
  text-indent: -9999px;
}
.view-mode a.list {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../images/list-light.png) no-repeat bottom right transparent;
  font-size: 0;
  text-indent: -9999px;
}
.review-padding {
  padding-left: 0;
}
.review-form-padding {
  padding-left: 0;
}
@media only screen and (min-width: 64.063em) {
  .review-form-padding {
    padding-top: 4rem;
}
}
@media only screen and (max-width: 40.063em) {
  .sidebar-button {
    padding-top: 1rem;
  }
  .colmargin {
    padding-left: 0;
    padding-right: 0;
  }
  .page-title {
    margin-left: 0;
  }
  *[role="main"] {
  padding-left: 0;
  padding-bottom: 2rem;
 }
 .fa-bars {
  margin-top: 1rem;
  margin-left: 0.5rem;
 }
 .row.product-media {
    margin: 2rem 0.5rem 0.5rem 0.5rem;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  
}


.grid-mode{
    .inner-wrapper{
      .details{
        padding:0.5rem;
      }
        .actions{
          .tax-details, .delivery-time{
            display:none;
          }
        }
        padding:0;
        .product-image{
          img{
            width:100%;
            margin:0;
          }
        }

      }
    
  }
