/* Off canvas layout CSS/JS provided by or adapted from work by Jason Weaver and Luke Wroblewski
   Requires
   globals.css
   grid.css */

  body.off-canvas { padding: 0; margin: 0; }

  .container { width: 100%; }
  //.row { overflow: hidden; }
  .row .row { overflow: visible; }

  .paneled .container { overflow: hidden; }
  .paneled .row { width: 100%; }

  [role="main"]:before { content: " "; position: absolute; z-index: -1; top: 0; left: -100%; width: 100%; height: 100%; }

  [role="complementary"],
  [role="main"] { width: 100%; padding: 0 0px; display: block;	position: relative; z-index: 1; @include single-transition(all,.25s,ease-out); }

  .paneled [role="main"] { padding: 0; }
  .page-panel { width: 100%; padding: 0 15px; @include single-transition(.3s margin ease-in-out); background: #fff; }
  #switchPanels { margin: 0 -15px; }

  .hide-extras [role="complementary"] { display: block; }

  [role="navigation"]#topMenu { @include single-transition(.25s all ease-in); }
  [role="navigation"]#topMenu ul { margin-top: 0; }

  [role="complementary"] { margin-left: -21rem; width: 290px; float: left; z-index: 2; }
  [role="main"] { margin-left: 0; float: right; z-index: 1; position: relative; }

  .paneled [role="main"] { background: #fff; width: 500%; overflow: hidden; float: none; position: relative; left: 0; @include single-transition(.15s all ease-in); }
  .page-panel { min-height: 400px; float: left; margin: 0; width: 20%; }


  .active [role="complementary"] { margin-left: 0; }
  .active [role="main"] { margin-right: -80%; }

  .active-menu [role="navigation"]#topMenu { margin-top: 0 !important; }

  @media all and (min-width: 768px) {

    menu-button, .sidebar-button { display: none; }
    [role="complementary"] { width: $complementaryWidth; margin-left: 0; float: left; padding: 0 0px; }
    [role="main"] { width: $mainWidth; padding: 0 0px; }

    .paneled [role="main"] { width: 100%; padding: 0; background: #f4f4f4; left: 0 !important; }
    .page-panel { display: block; min-height: 800px; float: none; margin: 0; width: 100%; background: #f4f4f4; }

    .hide-extras [role="main"] { width: 100%; }
    .hide-extras [role="complementary"] { display: none; }

    [role="navigation"]#topMenu {	display: none; }

  }

.col1-layout [role="main"]{width: 100%;}
.col1-layout [role="complementary"]{display: none;padding: 0;}