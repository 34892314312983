.site-logo{
  margin-top: 0;
  padding:0;
  font-size: 0;
  min-height: 110px;
}
.store-currency label, .form-language label{
  display:none;
}
.demo-notice{
  text-align: center;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1;
  display: inline-block;
  width: 100%;
  padding: 0.7em 0;
}
.site-search {
  margin-top: 0;
}
#search_autocomplete {
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  z-index: 1;
  left: 15px !important;
}
#search_mini_form .form-search .search-autocomplete ul {
}
#search_mini_form .form-search .search-autocomplete li {
  list-style-type: none;
  border-bottom: 1px dotted #ddd;
}
.search-button {
  background-color: $white;
  transition: all 1s;

  &:hover{
    background-color: #eee;
    color:$primary-color;
  }
}

.icon-search {
  color: #b1adad;
}
.postfix {
  border-color: #ccc;
  border-right: none;
}
#search {
  border-right: none;
  box-shadow: none;
  background: #fff;
  border-color: #eee;
  background-color: scale-color($light-grey, $lightness: 50%);
}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .top-cart {
    margin-top: 0;
  }
}
@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  .top-cart {
    margin-top: 0;
  }
  .site-identity {
    padding-left: 0;
  }
  .top-cart-padd {
    padding-right: 0;
  }
}
@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  .top-cart {
    margin-top: 0;
  }
  .site-identity {
    padding-left: 0;
  }
  .top-cart-padd {
    padding-right: 0;
  }
}
@media only screen and (min-width: 120.063em) {
  .top-cart {
    margin-top: 0;
  }
  .site-identity {
    padding-left: 0;
  }
  .top-cart-padd {
    padding-right: 0;
  }
}
.cartbutton-margin {
  margin-top: 1rem;
}
@media only screen and (max-width: 40em) {
  .site-search {
    margin-left: 0;
  }
  .row {
    max-width: 100%;
  }
  .sheader-height {
    height: 30px;
  }
  .site-header {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  #search {
    width:100%;
    height: 2.3rem;
  }
  .mobile-logo {
    margin-bottom: 0.25rem;
  }
  .custom-column {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
  }
  .site-identity {
    margin-bottom: 1rem;
  }
}
#accmini {
  padding-bottom: 1rem;
}

.site-header{
  .fa{
    font-size:1.8em;
  }
}

.navigation{
  //border-bottom: 1px solid scale-color($light-grey,$lightness:50%);
}

.logopart{
  //background:$black-trans;
}

.site-search{
  padding:2px 0;
  a{
    color:$white;
    font-size:0.7rem;
    transition: all 1s;
    &:hover{
      color:$primary-color;
    }
  }
}

.top-bar{
  ul{
    li{
        a{
          transition: all 1s;
        }
      }
    }
}