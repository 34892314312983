@media #{$large-up}{@include sticky-footer(340px, ".off-canvas-wrap", ".layout-footer", ".site-footer");}
@media #{$medium-only}{@include sticky-footer(930px, ".off-canvas-wrap", ".layout-footer", ".site-footer");}
.panel {
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f2f2f2;
}
.body-img {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 40em) {
  .body-img {
    margin-top: 2rem;
  }
  }
.subtitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.category-img {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.home-container{
  .std{
    p{
      display:none;
    }
  }
  background:none;
  .orbit-container{
    left:0;
    li{
      img{
        min-width:100%;
      }
    .colmargin{
      background:none;
    }
    }
  }
}

.clcopyright{
  display:none;
}
.news-item{
  .news-container{
    background:$darkgreen;
    min-width:100%;
    //padding:1em;
    position: relative;
    overflow: hidden;

    @include maintain-ratio();

    .news_image{

        margin:0;
        padding:0;
      img{
        display: block;
        width: 100%;
        height: auto;
        background-position: center center;
        margin:0;
        padding:0;
      }
    }

    .news-content{
      color:$white;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      display: block;
      width: 100%;
      height: 100%;
      .description{
        padding: 0.8vw 15px;
      }
      a{
        color: $white;
      }
       h5{
        font-size:2.0vw;
        padding: 0.8vw 15px;
        @media #{$medium-only} { font-size:4.0vw;padding: 1.6vw;}
        @media #{$small-only} { font-size:6.0vw;padding: 2.4vw;}
        line-height: 90%;
        text-align: right;
        
      }
      .buttonline{
        width:100%;
        background:$white;
        padding:0.8vw;
        font-size:2.0vw;
        @media #{$medium-only} { font-size:4.0vw;padding: 1.6vw;}
        @media #{$small-only} { font-size:6.0vw;padding: 2.4vw;}
        text-align: right;
        position:absolute;
        bottom: 0.1em;
        a{
          color:$darkgreen;
        }
      }
      .description p{
        text-align: right;
        font-size:1.0vw;
        @media #{$medium-only} { font-size:2.0vw;}
        @media #{$small-only} { font-size:4.0vw;}
        line-height: 1.4;
        margin: 0;
      }
    }
  }
  .withimage{
    background:none;
    .news-content{
      background: scale-color($white, $lightness: 50%, $alpha: -20% );
      width: 60%;
      left: 40%;
      color: $darkgreen;
      a{
        color: $darkgreen;
      }
      .buttonline{
        background:$darkgreen;
        a{
          color:$white;
        }
      }
    }
  }
}

.orbit-container{
.orbit-slides-container{
.orbit-caption{
  overflow:hidden;
  min-height: 50%;
  margin:0 0 1.5em 0;
  width:70%;
  right:0;
  background: scale-color($backgroundneg, $lightness:0%, $alpha: -20%);
  padding:1.5em;
  .header{
    width:30%;
    position:absolute;
    right: 120px;
    h5{
      font-size: 2.0vw;
      font-weight:100;
      line-height: 90%;
      text-align: left;
    }
  }
  .for-small{
      width:90%;
      left:10px;
      top:10px;
    }

  .description{
    width:calc(70% - 130px);
    position:absolute;
    right: calc(130px + 30%);
    p{
      font-size: 0.8vw;
      padding: 0 1.5em;      
    }
  }
  .buttonline{
    position:absolute;
    right:20px;
    top:-10px;
    padding:0;
    a{
      font-size: 8vw;
      margin:-10px 0;
    }
  }
}
}
}