.fieldset-panel{
  border: 0 none;
  padding: 0;
}
.ratings-table th,
.ratings-table td { font-size:11px; line-height:1.15; padding:3px 0; }
.ratings-table th { font-weight:bold; padding-right:8px; margin-left:0px; }
.product-review .ratings-table { margin:0 0 10px; }

.no-rating { margin:0; }

.ratings { font-size:11px; line-height:1.25; margin:0px 0; }
.ratings strong { float:left; margin:1px 3px 0 0; }
.ratings .rating-links { margin:0; }
.ratings .rating-links .separator { margin:0 2px; }
.ratings dt {}
.ratings dd {}
.rating-box { width:69px; height:13px; font-size:0; line-height:0; background:url(../images/bkg_rating.gif) 0 0 repeat-x; text-indent:-999em; overflow:hidden; }
.rating-box .rating { float:left; height:13px; background:url(../images/bkg_rating.gif) 0 100% repeat-x; }
.ratings .rating-box { float:left; margin-right:0.5rem; margin-top:0.2rem; }
.ratings .amount {}

.mailto-friend {
  margin-bottom:0.1em;
}
