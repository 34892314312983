.scrollToTop{
  width:40px; 
  height:50px;
  padding:10px; 
  text-align:center; 
  background: whiteSmoke;
  font-weight: bold;
  color: #444;
  text-decoration: none;
  position:fixed;
  bottom: 30px;
  right:1px;
  display:none;
  background: transparent;
}
.scrollToTop:hover{
  text-decoration:none;
}