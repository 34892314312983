.current {
  color: #333;
}
.block-acc-margin {
  margin-top: 1rem;
}
@media only screen and (max-width: 40.063rem) {
	.dashboard {
		margin: 0 0.5rem 0 0.5rem;
	}
}