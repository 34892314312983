//@include sticky-footer(300px, ".main-container", ".layout-footer", ".site-footer");
.site-footer {
  //background: $white-trans;
  margin:50% 0 0 0;
  a{
    color:$footer-font-color;
    transition: 1s ease-out;
    &:hover{
      color:scale-color($footer-font-color, $lightness:+20%);
    }
  }
}
.site-footer-content {
  max-width: 90%;
}
.site-footer-info {
  max-width: 100%;
  background: $black;
  color:#ffffff;
  a{
    color:#ffffff;
    &:hover{
      color:$primary-color;
    }
  }
}
.footer-content {
  margin-top: 2rem;
  em{
    font-size:2em;
  }
  h2{
    font-size: 1em;
  }
  table{
    background:none;
    border:none;
    width:90%;
    tr{
      background:none;
      border:none;
      td{
        vertical-align: top;
        border-top:1px solid $black;
        margin:0;
        padding:1em 0 1em 0;
        div{
          font-size:2em;
        }
      }
    }
  }
  .block-subscribe{
    width:90%;
    .block-title{
      font-size: 1em;
      margin-bottom: 1.5em;
    }
    .form-subscribe-header{
      label{
        font-size: 0.875rem;
        font-weight:300;
      }
    }
    input{
      width:100%;
    }
  }
}
.footer-content .input-text {
  width: 40%;
}
.img-foot {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.co-page {
  margin-top: 1rem;
}
.address-padd {
  background: #f9f9f9;
}
.footer-touch {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.text-foot {
  padding-right: 0;
}
.logo-foot {
  padding-left: 0;
}
.co-page {
  padding-left: 0;
}
.html-icon {
  padding-right: 0;
}
@media only screen and (max-width: 40em) {
  #uvTab {
    display: none;
  }
  .footer-touch {
    margin-top: 1rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .img-foot {
    margin-bottom: 0;
  }
  .footer-content {
    margin-top: 0;
  }
  .logo-foot {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .text-foot {
    padding-right: 1rem;
  }
  .logo-foot {
    padding-left: 1rem;
  }
  .co-page {
    padding-left: 1rem;
  }
  .html-icon {
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .text-foot {
    padding-right: 1rem;
  }
  .logo-foot {
    padding-left: 1rem;
  }
  .co-page {
    padding-left: 1rem;
  }
  .html-icon {
    padding-right: 1rem;
  }
}

button.button.tiny{
  background: $backgroundneg;
  &:hover{
    background:scale-color($backgroundneg, $lightness: +14%);
  }
}

.site-footer{
  h2{
    color:#222;
  }
}