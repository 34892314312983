.site-nav {
  /*
  border-top:1px solid #e7e7e7;
  border-bottom:1px solid #e7e7e7;
  border-left:1px solid #e7e7e7;
  border-right:1px solid #e7e7e7;
  */
}
.custom-column {
  padding-left: 0;
  padding-right: 0;
}
.fixed {
  //border-bottom: 1px solid #efefef;
  padding-left: 12%;
  background: #000000;
}
@media only screen and (max-width: 40em) {
	.top-bar button {
		padding-top: 0;
	}
	.site-logo {
		padding-left: 0 !important;
	}
}

.top-bar-section{
  //overflow:hidden;
  min-height: 50px;
  padding:auto 0;
}
