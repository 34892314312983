.product-view{
  .nephritis-flat-button{
    color:$black;
    transition:all 1s;
    &:hover{
      background-color:scale-color($primary-color,$lightness:-30%)!important;
    }
  }
  color:$black;
  .cart-margin{
    margin:0 0 0 0;
  }

  .delivery-time, .availability p{
    color:$black;
  }
  .nephritis-width{
    width:100%;
  }
  a{
    color:$black;
    padding:0.2rem;
    margin:-0.2rem;
    transition:background 1s;
    transition:color 1s;
    &:hover{
      color:$primary-color;
      background:$black;
    }
  }
  .add-to-links{
    display:none;
  }
  .product-options-wrapper, .configurable-option, .tax-details, .price-box{
    margin:0.0rem;
  }
}
.product-essential {
  padding-left: 0;
  padding-right: 0;
}

.product-shop{
.product-name{
  font-weight: 300;
  color:$black;
  text-decoration:underline;
  }
  .required{
    display:none;
  }
}

.product-options-bottom{
  padding:0;
  .price-box, .tax-details, .baseprice-box{
    display:none;
  }
}
.product-shop .email-friend, .product-shop .no-rating{
  margin-bottom: 0;
}

.product-shop .input-text.qty{
  width: 33%;
  display: inline;
}

.product-shop .add-to-cart .inline-list li{
  line-height: 3;
  label.qty-align{
    padding:1rem 0;
    border:1px solid #ff0000;
  }
}

.product-shop .price-box{
  margin-bottom: 1em;
  font-weight: 600;
}

.product-shop .short-description{
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  font-weight:normal;
  li{
    list-style-type:square;
  }
}

.input-box{
  width:25%;
}
.product-shop{
  .tax-details{
    font-weight:normal;
  }
}
.product-shop .short-description h2{
  font-size: 1.4rem;
  font-weight: 300;
  margin-left: -0.1rem;
}
.product-shop .std{
  /* @todo: Candidate for refactoring: global-css-rule; */
  line-height: $paragraph-line-height;
}
.col2-left-layout {
  margin-top: 0;
}
.separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.tabs-content {
  border: 1px solid #efefef;
  padding-left: 1rem;
}
.additional-data {
  padding-left: 0;
  padding-right: 0;
}
.configurable-option {
  margin-bottom: 0;
}
.configurable-dd {
  margin-bottom: 0;
}
.required-entry-conf, .required-entry-conf:hover {
  background: white url("../images/arrow.png") no-repeat scroll 98% center;
}
.price-box {
  margin-left: 0.0rem !important;
  margin-bottom: 1rem !important;
  price{
    font-size:2rem;
  }
}
.product-shop {
  padding-right: 0;
}
.tabs-padd {
  padding-left: 0;
  padding-right: 1rem !important;
  padding-top: 2rem;
  margin-bottom: 2rem !important;
}
.availability {
  margin-top: 1rem;
}
.list-style {
  list-style-type: none;
  float: left;
  margin-right: 1em;
}
.product-shop .short-description {
margin: 2em 0;
}
.product-image-gallery .gallery-image.visible {
display: block;
}
.product-image-gallery .gallery-image {
display: none;
}
.cart-margin {
  margin-bottom: 0;
}
.add-to-links {
  margin-bottom: 0.5rem;
}
.qty-align {
  position: relative;
  top: 60%;
  transform: translateY(10%);
}
.bundle-qty-align {
  position: relative;
  top: 40%;
  transform: translateY(40%);
}
.bundle-option-select, .bundle-option-select:hover {
  background: white url("../images/arrow.png") no-repeat scroll 98% center;
}
.downloadable-links {
  background-color: #fff;
  color: #333;
  margin-top: -0.2rem;
}
.downloadable-label {
  display: inline;
  margin-right: 1rem;
}
.sample-font {
  font-size: 0.9rem;
}
.grouped-items-table {
  width: 100%;
}
.grouped-table-price {
  width: 15%;
}
.grouped-table-qty {
  width: 20%;
}
.product-page-image {
  border: 1px solid #e9e9e9;
  margin-left: -0.1rem;
  margin-right: 1rem;
}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .product-shop {
    padding-right: 1rem;
  }
  .product-img-box {
    padding-left: 1rem;
  }
}
@media only screen and (max-width: 40em) {
  .product-shop {
    padding-right: 1rem;
  }
  .product-img-box {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .tabs-padd {
    margin-left: 0.5rem !important;
  }
  .review-padding {
    margin-left: 0.5rem;
  }
  .form-list {
    margin-left: 0.5rem !important;
  }
  .customer-reviews {
    padding-left: 1.5rem;
  }
  .custom-review-heading {
    margin-left: -1rem;
  }
}
#wrap {
  z-index: 1 !important;
}
