.breadcrumbs {
  display: block;
  padding: 1.5rem 0.875rem 0;
  overflow: hidden;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  border-style: solid;
  border-width: 0px;
  background-color: transparent;
  border-color: gainsboro;
  border-radius: 3px;
}
.breadcrumbs > * a {
  color: #a0a0a0;
}
.breadcrumbs > * {
  margin: 0;
  float: left;
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  text-transform: uppercase;
  color: #a0a0a0;
}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
.breadcrumbs {
  padding-left: 1rem;
}
#sidebar {
  padding-left:1rem;
}
}