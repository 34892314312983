.form-list{
  list-style-type: none;
  margin: 0;
}
select {
  font-family: "Open Sans";
}
.opc .step-title h2 {
  font: bold 12px/14px ProximaNova !important;

}
.breadcrumbs-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.category-products {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.product-media {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
.inner-wrap {
  margin-top: 0.5rem;
}
li {
  list-style-type:none;
}
.col3-padd {
  padding-left: 0;
  padding-right: 0;
}

.mobile-logo{
  padding:0.3rem 0;
}

h3{
  padding:0.5rem 0.1rem;
  .widget-category-link{
    padding:0 0.5rem;
    a{
      color:$primary-color;
      background:$black;
      padding:0.5rem;
      transition:all 1s;
      &:hover{
        color:$black;
        background:$primary-color;
      }
    }
  }
}